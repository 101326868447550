import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';


const NotFound = () => {

    return (
        <>
		<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="d-flex flex-column flex-root">
                <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain " style={{ backgroundImage: `url("/assets/media/illustrations/progress-hd.png")` }}>
                    <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-5">
                        <a href="/" className="mb-10 pt-lg-5">
                            <img alt="Logo" src="https://cdn-adocrm.s3.amazonaws.com/static/ado-crm-logo.png" className="h-50px mb-5" />
                        </a>
                        <div className="pt-lg-10">
                            <h1 className="fw-bolder fs-4x text-gray-700 mb-10">Page Not Found</h1>
                            <div className="fw-bold fs-3 text-gray-400 mb-15">The page you looked not found!</div>
                            <div className="text-center">
                                <a href="/" className="btn btn-lg btn-primary fw-bolder">Go to Dashboard</a>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-center flex-column-auto p-10">
                        <div className="d-flex align-items-center fw-bold fs-6">
                            <Link to="/support" className="text-muted text-hover-primary px-2">Contact Us</Link>
                        </div>
                    </div>
                </div>
            </div>
		</div>
        </>);

};

export default NotFound;
